import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import { mergeArrayObjectsById } from "@/app/util.mjs";
import {
  QUEUE_SYMBOLS,
  SERVICES_TO_REGIONS,
} from "@/game-lol/constants/constants.mjs";
import {
  CURR_SEASON_PROMO,
  SUPPORTED_QUEUES,
} from "@/game-lol/constants/season-review.mjs";
import LolMatch from "@/game-lol/models/lol-match.mjs";
import LCUMatch from "@/game-lol/models/lol-match-lcu.mjs";
import LolMatchList from "@/game-lol/models/lol-match-list.mjs";
import LCUMatchList from "@/game-lol/models/lol-match-list-lcu.mjs";
import LoLPlayerChampionSeasonStats from "@/game-lol/models/lol-player-champion-season-stats.mjs";
import LoLPlayerChampionStats from "@/game-lol/models/lol-player-champion-stats.mjs";
import LoLPlayerStyles from "@/game-lol/models/lol-player-styles.mjs";
import LoLSummoner from "@/game-lol/models/lol-summoner.mjs";
import LCUSummoner from "@/game-lol/models/lol-summoner-lcu.mjs";
import { loadLocalMatchlist } from "@/game-lol/utils/actions.mjs";
import * as API from "@/game-lol/utils/api.mjs";
import {
  getLCUAvailable,
  getLCUMatch,
  getLCUMatchList,
  getLCUSummonerProfile,
} from "@/game-lol/utils/lol-client-api.mjs";
import { matchStatePath } from "@/game-lol/utils/match-utils.mjs";
import { shouldShowPromo } from "@/game-lol/utils/season-review.mjs";
import { getQueueDetails } from "@/game-lol/utils/symbol-queue.mjs";
import {
  getDerivedId,
  getDerivedQueue,
  isPBE,
} from "@/game-lol/utils/util.mjs";
import deepMerge from "@/util/deep-merge.mjs";
import { devError } from "@/util/dev.mjs";
import orderArrayBy from "@/util/order-array-by.mjs";
import unionBy from "@/util/union-by.mjs";

// path regExp: /lol/profile/(.*)/(.*)
async function fetchData(params, searchParam, state) {
  const [region, name] = params;

  if (isPBE(region)) return;

  if (!region || !name) {
    devError("Missing region or name.");
    return;
  }

  const isUpdate = Boolean(state?.isUpdate);
  let derivedId = getDerivedId(region, name);

  const {
    volatile: { currentSummoner, currentRegion },
  } = readState;

  const isLoggedInAccount = currentSummoner === derivedId;

  const localMatchList =
    (isLoggedInAccount && (await loadLocalMatchlist(derivedId))) || [];

  const isUnsupportedRegion = !SERVICES_TO_REGIONS[region.toLowerCase()];

  const useLCU =
    isUnsupportedRegion && currentRegion === region && getLCUAvailable();

  if (isUnsupportedRegion && !useLCU) {
    devError("Can not retrieve profile info by any means.");
    return;
  }

  const profileOptions = {
    mergeFn: (currVal, newVal) => {
      return deepMerge(currVal, newVal, true);
    },
  };
  const profilePath = ["lol", "profiles", derivedId];
  let profile = isUnsupportedRegion
    ? await getData(
        () => getLCUSummonerProfile(name),
        LCUSummoner,
        profilePath,
        profileOptions,
      )
    : await getData(
        API.getSummoner({ region, name }),
        LoLSummoner,
        profilePath,
        profileOptions,
      );

  profile = readState.lol.profiles[derivedId];
  if (!profile) return;

  const riotAccount = profile.riotAccount;
  if (riotAccount) {
    const { gameName, tagLine } = profile.riotAccount;
    derivedId = getDerivedId(region, null, gameName, tagLine);
  }

  // For "recently played with"
  const playerStylePromise = getData(
    API.getPlaystyles(region, profile.id),
    LoLPlayerStyles,
    ["lol", "playerStyle", derivedId],
    { shouldFetchIfPathExists: !isUpdate },
  );

  // Ranked Stats
  const rankedQueue = getQueueDetails(QUEUE_SYMBOLS.rankedSoloDuo).gql;
  const rankedStatsPromise = getData(
    API.getPlayerChampionStats({
      region: region?.toUpperCase(),
      leagueProfileId: profile.id,
      ...(rankedQueue &&
      rankedQueue !== getQueueDetails(QUEUE_SYMBOLS.custom).gql
        ? { queue: rankedQueue }
        : {}),
    }),
    LoLPlayerChampionStats,
    ["lol", "playerChampionStats", getDerivedQueue(derivedId, rankedQueue)],
    { shouldFetchIfPathExists: !isUpdate },
  );

  // ARAM Stats
  const aramQueue = getQueueDetails(QUEUE_SYMBOLS.aram).gql;
  const aramStatsPromise = getData(
    API.getPlayerChampionStats({
      region: region?.toUpperCase(),
      leagueProfileId: profile.id,
      ...(aramQueue && aramQueue !== getQueueDetails(QUEUE_SYMBOLS.custom).gql
        ? { queue: aramQueue }
        : {}),
    }),
    LoLPlayerChampionStats,
    ["lol", "playerChampionStats", getDerivedQueue(derivedId, aramQueue)],
    { shouldFetchIfPathExists: !isUpdate },
  );

  const matchListOptions = {
    shouldFetchIfPathExists: !isUpdate,
    mergeFn: (curValue, newValue) =>
      mergeArrayObjectsById(curValue, newValue, "id", "id"),
  };
  const matchListPath = ["lol", "matchlists", derivedId];
  const matchOptions = {
    shouldFetchIfPathExists: false,
  };

  function getMatchFromAPI({ id, gameId, region }) {
    return getData(
      API.getMatch(region, Number(gameId)),
      LolMatch,
      matchStatePath(null, null, id),
      matchOptions,
    );
  }

  function getMatchListFromAPI() {
    return getData(
      API.getMatchList(region, profile.puuid),
      LolMatchList,
      matchListPath,
      matchListOptions,
    );
  }

  function getMatchListFromLCU() {
    return getData(
      getLCUMatchList(profile.puuid),
      LCUMatchList,
      matchListPath,
      matchListOptions,
    );
  }

  const matchlistPromise = (
    useLCU ? getMatchListFromLCU : getMatchListFromAPI
  )().then((matchlist) => {
    if (!state) return null;
    const sanitizedMatchList =
      matchlist && !(matchlist instanceof Error) ? matchlist : [];
    const mergedMatchlist = orderArrayBy(
      unionBy(localMatchList, sanitizedMatchList, "id"),
      "id",
      "desc",
    );

    // Only fetch matches that is visible
    const matchesToLoad = mergedMatchlist.filter((match) => {
      const matchIsVisible = state.transient?.visibleMatches?.[match.id];
      return matchIsVisible;
    });

    return Promise.all(
      matchesToLoad.map(({ id }) => {
        const matchPath = matchStatePath(null, null, id);
        const [region, gameId] = id.split("_");
        return useLCU
          ? getData(
              () => getLCUMatch(gameId),
              LCUMatch,
              matchPath,
              matchOptions,
            )
          : getMatchFromAPI({
              id,
              gameId,
              region,
            });
      }),
    );
  });

  const seasonReviewPromises = shouldShowPromo()
    ? SUPPORTED_QUEUES.map((queue) => {
        return getData(
          API.getPlayerChampionSeasonStats({
            region: region.toUpperCase(),
            leagueProfileId: profile.id,
            queue: queue.enum,
            season: CURR_SEASON_PROMO,
          }),
          LoLPlayerChampionSeasonStats,
          [
            "lol",
            "playerChampionSeasonStats",
            CURR_SEASON_PROMO,
            queue.stateKey(derivedId),
          ],
          { shouldFetchIfPathExists: false },
        );
      })
    : [];

  return Promise.all([
    matchlistPromise,
    playerStylePromise,
    rankedStatsPromise,
    aramStatsPromise,
    ...seasonReviewPromises,
  ]);
}

export default fetchData;
